import React, { Component } from 'react'
import { ROUTE, UI } from '../config'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Link } from 'react-router-dom'

import Loader from '../components/Loader'
import ErrorMessage from '../components/ErrorMessage'
import BreadCrumb from '../components/BreadCrumb'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as UserActions from 'tw-oi-core/actions/UserActions'
import * as ContentsActions from 'tw-oi-core/actions/ContentsActions'

import { trackVideoView, trackTextView, trackVideoChapterClick } from 'tw-oi-core/services/analytics'

import TopicVideo from '../components/TopicVideo'
import TopicText from '../components/TopicText'
import TopicLayout from '../components/TopicLayout'
import { getRootTopicForWarningLightsPublication, isWarningLightsRootTopic } from '../utils/topics'

class Topic extends Component {

  static placements = {
    [ROUTE.TOPPICKS]: 'Top Picks',
    [ROUTE.EXPLORE]: 'Explore',
    [ROUTE.SEARCH]: 'Search',
  }

  static propTypes = {
    topics: ImmutablePropTypes.list.isRequired,
    publications: ImmutablePropTypes.list.isRequired,
    foldersIndex: ImmutablePropTypes.map,
    currentAsset: PropTypes.shape({
      content: PropTypes.string.isRequired,
      objectKey: PropTypes.string
    }),
    fetchingAsset: PropTypes.bool,
    errorAsset: PropTypes.object,
    ContentsActions: PropTypes.shape({
      getAsset: PropTypes.func.isRequired
    }).isRequired,
    UserActions: PropTypes.shape({
      addRecent: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        topicId: PropTypes.string.isRequired,
        referrer: PropTypes.string.isRequired,
        time: PropTypes.string,
      }).isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    currentYear: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    baseRoute: PropTypes.string.isRequired,
    userMedia: PropTypes.shape({
      isDesktop: PropTypes.bool
    }).isRequired
  }

  constructor(props) {
    super(props)

    const { topics, foldersIndex, match, history, baseRoute } = props

    // TODO: move topic search by ID to router level and remove "match" & "topics" from props
    let
      activeFolder = null,
      currentTopic = topics.find(item => item.get('resourceKey') === match.params.topicId)
    // redirect to /browse/folder/XXXX if topicId belongs to folder
    if (!currentTopic) {
      currentTopic = foldersIndex.get(match.params.topicId)
      if (currentTopic) {
        const url = `${baseRoute}${ROUTE.BROWSE}${ROUTE.FOLDER}/${match.params.topicId}`
        history.replace(url)
        currentTopic = null
      }
    } else {
      activeFolder = foldersIndex.find(folder => {
        return folder.get("contents").keySeq().contains(match.params.topicId)
      })
    }

    this.state = {
      currentTopic,
      activeFolder,
      time: null
    }
  }

  componentDidMount() {
    const { currentAsset, match, currentYear, currentModel } = this.props
    const { getAsset } = this.props.ContentsActions
    const { addRecent } = this.props.UserActions
    const { currentTopic } = this.state

    if (currentTopic && !currentTopic.get('video')) { // Do not load HTML asset in case of video-only topics
      if (currentAsset === null || currentAsset.objectKey !== currentTopic.getIn(['bodyHtml', 'objectKey'])) {
        getAsset(currentTopic.getIn(['bodyHtml', 'objectKey']))
      }

      trackTextView(currentTopic.get('title'))
    }

    if (currentTopic && currentTopic.get('video')) {
      trackVideoView(currentTopic.get('title'))
    }

    if (currentTopic) {
      addRecent(currentYear, currentModel, currentTopic.get('resourceKey'))
    }

    if (match.params.time) {
      const timeOffset = parseInt(match.params.time, 10)
      this.playFromTime(timeOffset)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.topicId !== this.props.match.params.topicId) {
      let currentTopic = this.props.topics.find(item => item.get('resourceKey') === this.props.match.params.topicId)

      this.setState({currentTopic})

      this.props.ContentsActions.getAsset(currentTopic.getIn(['bodyHtml', 'objectKey']))
    }
  }

  /**
   * Updates the topic URL and starts video playback from specified time
   *
   * @param {Number} time
   */
  onVideoMarkerClick(time) {
    const { history, match } = this.props
    const topicId = match.params.topicId
    const url = `${this.getBackRoute()}${ROUTE.TOPIC}/${topicId}/${time}`

    // play video
    history.replace(url)
    this.playFromTime(time)

    // track analytics event
    const { currentTopic } = this.state
    const currentMarker = currentTopic.get('video').get('markers').find(x => x.get('timestamp') === time)
    if (currentMarker) {
      const markerTitle = currentMarker.get('title') + ' :: ' + currentTopic.get('title')
      trackVideoChapterClick(markerTitle)
    }
  }

  /**
   * Starts video playback from provided time
   *
   * @param {Number} time
   */
  playFromTime(time) {
    this.setState({
      time: time
    })
  }

  getBackRoute() {
    return this.props.match.url.split(ROUTE.TOPIC)[0]
  }

  render() {
    const { currentAsset, fetchingAsset, match, errorAsset, userMedia, foldersIndex, baseRoute, topics, publications } = this.props
    const { currentTopic, activeFolder } = this.state
    const referrer = '/' + match.params.referrer

    if (!currentTopic) {
      return <Loader type="status" className="inverse">Topic not found</Loader>
    }

    let subTitle = null, backRoute = null, backTitle = null

    if (userMedia.isDesktop) {
      if (referrer === ROUTE.BROWSE) {
        subTitle = <BreadCrumb {...{ activeFolder, foldersIndex, baseRoute }} inactive />
      } else {
        subTitle = <ul className="topics-breadcrumb inactive"><li><Link to={match.url.split(ROUTE.TOPIC)[0]}>{Topic.placements[referrer]}</Link></li></ul>
      }
    } else {
      subTitle = <div className="topics-subtitle">{this.state.currentTopic.get('title')}</div>

      if (referrer === ROUTE.BROWSE) {
        backRoute = `${baseRoute}${ROUTE.BROWSE}${(activeFolder && activeFolder.get('resourceKey')) ? `${ROUTE.FOLDER}/${activeFolder.get('resourceKey')}` : ''}`
        backTitle = (activeFolder && activeFolder.get('resourceKey')) ? activeFolder.get('title') : UI.BROWSE_TITLE
      } else if (referrer === ROUTE.WARNING_LIGHTS) {
        if (isWarningLightsRootTopic(currentTopic.get('title'))) {
          backRoute = baseRoute
          backTitle = 'Manuals'
        } else {
          backRoute = `${baseRoute}${ROUTE.WARNING_LIGHTS}${ROUTE.TOPIC}/${getRootTopicForWarningLightsPublication(topics,publications).get('resourceKey')}`
          backTitle = getRootTopicForWarningLightsPublication(topics,publications).get('title')
        }
      } else {
        backRoute = this.getBackRoute()
        backTitle = Topic.placements[referrer]
      }
    }

    return (
      <TopicLayout
        className={currentTopic.get('video') && "video"}
        backRoute={backRoute}
        backTitle={backTitle}
        title={this.state.currentTopic.get('title')}
        subTitle={subTitle}
        centerContent
      >
        { /* show video topic content if video data is available */}
        {currentTopic.get('video') ?
          <TopicVideo
            video={currentTopic.get('video').toJS()}
            onVideoMarkerClick={(t) => this.onVideoMarkerClick(t)}
            onPlay={trackVideoView.bind(this, currentTopic.get('title'))}
            time={this.state.time} /> :

          // show text topic content otherwise
          // TODO: move content loading and error indication to TopicText component
          errorAsset ?
            <ErrorMessage className="inverse inline" retryAction={() => this.componentDidMount()} /> :
            currentAsset === null || fetchingAsset ?
              <Loader className="inverse" /> :
              <TopicText
                baseRoute={baseRoute}
                content={currentAsset.content}
                foldersIndex={foldersIndex}
              />
        }
      </TopicLayout>
    )
  }
}

function mapStateToProps({ contents, vehicle, user }) {
  const { topics, foldersIndex, currentAsset, fetchingAsset, errorAsset, publications } = contents
  const { currentYear, currentModel, currentBrand } = vehicle
  const { media: userMedia } = user

  return { topics, foldersIndex, currentAsset, fetchingAsset, errorAsset, currentYear, currentModel, currentBrand, userMedia, publications }
}

function mapDispatchToProps(dispatch) {
  return {
    ContentsActions: bindActionCreators(ContentsActions, dispatch),
    UserActions: bindActionCreators(UserActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Topic)

